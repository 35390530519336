import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import LandingpagePersonalHeader from "~components/sections/landingpages/LandingpagePersonalHeader";
import SeoText from "~components/sections/landingpages/SeoText";

export default function Page({ data, location }) {
	const keyword = "Fullstack Entwickler";
	const title = "Fullstack Entwickler ▷ Freelancer zum Fixpreis";
	const description = "Suchst Du einen zuverlässigen Fullstack Entwickler? Wir helfen Dir gerne bei Deinem Projekt!";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<LandingpagePersonalHeader
				title={title}
				description={description}
				keyword={keyword}
				schemaImage={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
				price="90"
				experienceYears="3"
				skills={["JavaScript", "Node", "Express", "React"]}
				siteUrl={data.site.siteMetadata.siteUrl}
				path={location.pathname}
			/>

			<SeoText
				text={[
					{
						headline: "Was ist ein Fullstack Entwickler?",
						headlineAs: "h2",
						text: [
							"Fullstack Entwickler oder auch Fullstack Developer sind Webentwickler mit einem umfangreichen Fachwissen in allen Bereichen der Siteerstellung. Sie sind Allrounder, die einer Webanwendung nicht nur ein effektives Gerüst geben, sondern auch deren Datenverwaltung, Administration und optische Ausgestaltung übernehmen können. Ihre Kompetenzen aus den Bereichen Management, Planung, Programmierung und Interfacedesign ermöglichen ihnen ein ganzheitliches Arbeiten innerhalb der Frontend- und Backendentwicklung. Durch ihren Einsatz kann die Anzahl der an einem Webprojekt beteiligten Mitarbeiter beträchtlich gesenkt werden. Dies reduziert Fehlerquellen und ermöglicht die Erstellung sauber aufeinander zugeschnittener Projektkomponenten. In größeren Teams behalten Fullstack Developer leicht den Überblick und können im Sinne eines Ansprechpartners als Schnittstelle zwischen einzelnen Abteilungen dienen.",
						],
					},
					{
						headline: "Frontend- und Backendentwicklung",
						headlineAs: "h2",
						text: [
							"Was bedeutet es konkret, wenn wir von Frontend- und Backendentwicklung sprechen? Ein Frontendentwickler ist für die benutzerseitige Programmierung eines Webprojektes zuständig. Er setzt das geplante Layout um und erstellt ein barrierefreies und benutzerfreundliches Interface. Sein Aufgabenfeld liegt somit in der userseitigen Programmierung. Seine Arbeit basiert auf einem konsequenten Austausch mit dem entsprechenden Backendentwickler. Dessen Kompetenzbereich liegt wiederum in der Konzipierung und Entwicklung der serverseitigen Komponenten einer Webanwendung. Er beschäftigt sich mit allen im Hintergrund laufenden Anwendungen und Schnittstellen, auftretenden Fehlern, der Datenverwaltung und Systemintegration. Auch Planungs- und Aktualisierungsprozesse können in seinen Aufgabenbereich fallen. Der Backendentwickler sorgt für die vom Anwender meist unbemerkte Bereitstellung von Daten und Funktionen, die der Frontendentwickler diesem über die optische Benutzeroberfläche und deren interaktive Funktionen zugänglich macht. Dieses System verlangt nach einer engen Zusammenarbeit zwischen beiden Komponenten und ist relativ störanfällig. Hier liegt der große Vorteil eines Fullstack Developers. Er hat beide Seiten im Blick, was ihm ein flexibles Arbeiten und eine innovative Lösungsfindung ermöglicht. In größeren Unternehmen unterstützt er durch seine fachübergreifenden Kompetenzen ein einheitliches Auftreten. Bei kleineren Projekten kann er einen Großteil der anfallenden Aufgaben selbst übernehmen. So entstehen qualitativ hochwertige Konzepte, die merklich in einer einheitlichen Linie gefertigt wurden.",
							"Viele Technologien basieren auf JavaScript und daher ist es nicht verwunderlich, dass ein JavaScript Entwickler schnell als Fullstack Entwickler gilt. Zudem basieren die bekanntesten Bibliotheken und Frameworks sind React, Vue, Angular, Svetle für das Frontend und Express, Fastify für Node, also dem Backend, auf JavaScript. Suchst Du einen Fachmann in mit Kenntnissen in diesen Technologien, dann bist in uns den richtigen Partner gefunden.",
						],
					},
					{
						headline: "Aufgaben eines Fullstack Entwicklers",
						headlineAs: "h2",
						text: [
							"Der einzelne Fullstack Developer kann an jeder Stelle des Workflows einer Webentwicklung eingesetzt werden oder kümmert sich selbst um den kompletten Auftrag. Er berät den Kunden zu den Umsetzungsmöglichkeiten seiner Projektidee und analysiert im Weiteren die Anforderungen zur Umsetzung des Projektes. Fullstack Developer finden individuelle Lösungen für die Anliegen ihrer Kunden und planen darauf basierend die Architektur einer Webanwendung und deren Ausführung, einschließlich eines aussagekräftigen Designs. Sie müssen nicht nur über umfangreiche Programmierkenntnisse zur Erstellung des Backends verfügen, sondern sind auch in allen gängigen Prozessen zur Entwicklung von Web- und Bedienoberflächen bewandert. In großen Teams ist der Fullstack Developer auch häufig das Bindeglied zwischen einzelnen Entwicklern und Programmierern. Durch diese Arbeitsweise entstehen quasi alle Teile einer Webentwicklung aus einer Hand, was einen reibungslosen Systemablauf ermöglicht und im Zweifelsfall die Fehlersuche erleichtert. Folgerichtig gehört die Live-Schaltung der Site oder Anwendung, die eingehende und laufende Testung, Überwachung und Protokollierung ebenso zu den Aufgaben eines Fullstack Developers.",
						],
					},
					{
						headline: "Über uns",
						headlineAs: "h2",
						text: [
							<>
								{" "}
								Unter diesen Bedingungen betrachten wir uns nicht nur als Entwickler, sondern auch als Projektmanager.
								Unser Ziel ist es, unseren Kunden innovative und ganzheitliche Lösungen zu bieten. Basierend auf unseren
								Erfahrungen mit unterschiedlichen Programmiersprachen, Tools und Frameworks erstellen wir dein
								individuelles Projekt. Dabei arbeiten wir vorzugsweise in{" "}
								<Link to="/leistungen/javascript-entwickler/" className="underline">
									JavaScript
								</Link>
								, einer gängigen Programmiersprache und nutzen NodeJs und ExpressJs als Backend. Um für Dich als Kunden
								einzigartige Softwarelösungen produzieren zu können, entwickeln wir uns innerhalb dieses Themenkreises
								kontinuierlich weiter und halten unser Fachwissen stets auf dem neuesten Stand.
							</>,
						],
					},
				]}
			/>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/fullstack-entwickler/fullstack-entwickler_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
