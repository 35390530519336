import React from "react";
import { graphql, grapql, useStaticQuery } from "gatsby";
import { BriefcaseIcon, ClockIcon, LightBulbIcon, TerminalIcon } from "@heroicons/react/outline";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Container from "~components/common/Container";
import Button from "~components/common/Button";
import H1 from "~components/typography/H1";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import ReviewSlider from "~components/features/ReviewSlider";
function LandingpagePersonalHeader({
	title,
	description,
	keyword,
	schemaImage,
	price,
	experienceYears,
	skills,
	siteUrl,
	path,
}) {
	const data = useStaticQuery(graphql`
		{
			profileImage: file(relativePath: { eq: "lautenschlager-marketing-entwicklung_profil.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`);

	return (
		<>
			<SchemaProduct
				name={title}
				image={schemaImage}
				description={description}
				offer
				price={price}
				url={`${siteUrl}${path}`}
			/>
			<Breadcrumb
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: path,
					},
				]}
			/>

			<div className="py-6 bg-pattern">
				<Container noPadding>
					<div className="grid grid-cols-4 gap-8">
						<div className="col-span-4 md:col-span-1 md:text-center">
							<GatsbyImage
								image={getImage(data.profileImage)}
								alt={`${keyword} - Andreas Lautenschlager`}
								title={`${keyword} - Andreas Lautenschlager`}
								placeholder="blurred"
								className="max-w-xs mb-3 bg-white rounded-full md:mb-0"
							/>
						</div>
						<div className="col-span-4 md:col-span-2">
							<div>
								<H1 display className="mb-5 font-display">
									{keyword}: Freelancer zum Fixpreis
								</H1>
								<P className="mb-5">
									Bist Du auf der Suche nach einem <strong>{keyword}</strong> Freelancer? Gerne möchten wir Dir unsere
									Dienste anbieten. Wir achten primär auf Kommunikation, Code-Qualität und pünktliche Realisierung
									Deines Projekts.
								</P>
							</div>
							<div className="space-y-4">
								<div className="flex items-center">
									<BriefcaseIcon className="w-6 h-6 mr-3" />
									Remote aus Kassel, Nordhessen
								</div>
								<div className="flex items-center">
									<ClockIcon className="w-6 h-6 mr-3" />
									40 Stunden in der Woche
								</div>
								<div className="flex items-center">
									<LightBulbIcon className="w-6 h-6 mr-3" />
									mehr als {experienceYears} Jahre Berufserfahrung als {keyword}
								</div>
								<div className="flex items-center">
									<TerminalIcon className="w-6 h-6 mr-3" />
									{skills.map((skill, i) => {
										if (skills.length - i === 1) {
											return <React.Fragment key={skill}>{skill} </React.Fragment>;
										}
										return (
											<React.Fragment key={skill}>
												{skill}
												{", "}
											</React.Fragment>
										);
									})}
									u.v.m.
								</div>
							</div>
						</div>
						<div className="col-span-4 md:col-span-1">
							<div className="p-2 bg-pattern">
								<div className="p-3 bg-white">
									<H2>Stundensatz</H2>
									<P className="mt-4 text-sm">
										Wir bieten eine gesicherte Qualität zu einem Fixpreis. Dabei nehmen wir den Stundensatz als
										Rechengrundlage und erstellen passend für Deine Anforderungen ein festes Angebot, ohne lästige
										Stundenabrechnungen.
									</P>
									<P className="my-4">
										<span className="text-4xl font-bold leading-none">
											{price},- € <span className="text-sm font-normal text-gray-700">/ netto pro Stunde</span>
										</span>
									</P>
									<Button to={`${path}anfragen/`} text="Angebot einholen" className="w-full" />
								</div>
							</div>
						</div>
						<div className="col-span-4 md:col-span-3 md:col-start-2">
							<ReviewSlider />
						</div>
					</div>
				</Container>
			</div>
		</>
	);
}

export default LandingpagePersonalHeader;

LandingpagePersonalHeader.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	keyword: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	schemaImage: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	experienceYears: PropTypes.string.isRequired,
	skills: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	siteUrl: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
};
